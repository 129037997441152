import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import slugify from '../components/slugify'
import { ParallaxProvider, Parallax } from 'react-scroll-parallax';
import ImageModal from "../components/ImageModal"
import Img from "gatsby-image/withIEPolyfill"
import Layout from "../components/layout"

const Opengraph = ({ data, pageContext }) => {
  const projects = data.graphAPI.homepage.project
  const project = projects.find((iter) => {
    return iter.title === pageContext.title;
  })
  const slug = 'https://sugarlandlegacy.org/opportunities/'+slugify(project.title)

  return (
    < >
      <Helmet>
        <title>{project.title} - Sugar Land Legacy Foundation</title>
        <meta property='og:title'  content={project.title} />
        <meta name='twitter:title' content={project.title} />
        
        <link rel='canonical'       href={slug} />
        <meta property='og:url'  content={slug} />
        <meta name='twitter:url' content={slug} />

        <meta name='description'         content={project.excerpt} />
        <meta property='og:description'  content={project.excerpt} />
        <meta name='twitter:description' content={project.excerpt} />

        <meta property="og:image"  content={'https://sugarlandlegacy.org'+project.featuredImage.documentFile.publicURL} />
        
        <meta property='og:site_name' content='Sugar Land Legacy Foundation' />
        <meta property='og:type' content='website' />
      </Helmet>
      <Layout>
        <div id="opportunities" className="jump dark end">
          <ParallaxProvider>
            <div className="jump container swing-content">
              <h1 className="big-text has-text-centered" style={{ marginTop: 0}}>{project.title}</h1>
                <div id={"opportunities-"+slugify(project.title)} key={project.title} className="jump container swing-item">
                  <div className={`${project.featuredHasText === true ? "uncover-this" : "cover-this"} ${project.featuredImage.documentFile.childImageSharp.fluid.aspectRatio < 1 ? "tall" : ""}`}>
                    <Img className="content-image" fluid={project.featuredImage.documentFile.childImageSharp.fluid} />
                  </div>

                  <Parallax className="content-card-container" y={['-30px','30px']} >
                  <div className={`swing-this ${project.featuredImage.documentFile.childImageSharp.fluid.aspectRatio < 1 ? "with-tall" : ""}`}>
                  <div className="content-card">
                    <div dangerouslySetInnerHTML={{__html: project.content}}></div>
                  { project.additionalImages.length > 0 ? (
                    <div className="columns" style={{marginTop: '3rem'}}>
                      { project.additionalImages.map(image => (
                        <div key={image.documentFile.id} className="column is-6">
                            <ImageModal fluid={image.documentFile.childImageSharp.fluid} />
                          { image.caption !== '' ? (
                            <div dangerouslySetInnerHTML={{__html: image.caption}}></div>
                          ): (<></>)}
                        </div>
                      ))}
                    </div>
                  ) : (<></>)}
                  </div>
                  </div>
                  </Parallax>
                  <div className="jump-gap"></div>
                </div>
            </div>
          </ParallaxProvider>
        </div>

      </Layout>
    </ >
  )
}

export default Opengraph

export const pageQuery = graphql`
  query opengraph {
    graphAPI {
      homepage {
        project {
          id
          title
          content
          excerpt
          featuredImage {
            url
            created_at
            updated_at
            documentFile {
              id
              publicURL
              childImageSharp {
                fluid(maxWidth: 1116){
                  ...GatsbyImageSharpFluid_tracedSVG
                  aspectRatio
                }
              }
            }
            alternativeText
            caption
          }
          featuredHasText
          additionalImages {
            url
            created_at
            updated_at
            documentFile {
              id
              childImageSharp {
                fluid(maxWidth: 2000){
                  ...GatsbyImageSharpFluid_tracedSVG
                  aspectRatio
                }
              }
            }
            alternativeText
            caption
          }
        }
      }
    }
  }
`
